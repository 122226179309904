<div class="main-container">
  <mat-toolbar class="topbar telative">
    <button
      mat-icon-button
      class="cci-toolbar-button"
      (click)="sideNavToggle()"
      fxHide.gt-xs>
      <mat-icon>{{ iconText }}</mat-icon>
    </button>
    <div class="navbar-header" fxHide.lt-md>
      <a class="navbar-brand" href="index.html" fxShow="true">
        <b>
          <img
            src="./assets/images/logo-crew-check-in-dark.png"
            class="cci-branding-logo-expanded"
            alt="homepage" />
        </b>
      </a>
    </div>
    <span fxFlex></span>
    <aa-cci-header fxHide.lt-md></aa-cci-header>
  </mat-toolbar>

  <mat-sidenav-container
    class="cci-sidenav-container"
    [style.marginTop.px]="mobileQuery.matches ? 0 : 0">
    <mat-sidenav
      #sidenav
      id="snav"
      class="dark-sidebar pl-xs"
      [mode]="mobileQuery.matches ? 'side' : 'over'"
      fixedTopGap="0"
      [opened]="mobileQuery.matches"
      [disableClose]="mobileQuery.matches">
      <aa-app-sidebar></aa-app-sidebar>
    </mat-sidenav>

    <mat-sidenav-content [style.margin-left.px]="65" class="page-wrapper">
      <div class="page-content">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
