import { Injectable, EventEmitter } from '@angular/core';
import { ConfigurationRequest } from '../../../models/request/configuration-request';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { HttpService } from '../../../shared/services/http.services';
import { FormFieldConfig } from '../../../models/form/form-data';
import { appStrings } from '../../../constants';
import { caseSensitiveStringContains } from '../../../shared/utils/string-utils';
import { Store, select } from '@ngrx/store';
import * as fromRoot from '../../../shared/state/reducers';
import { User } from '../../../models/user';
import { Validators } from '@angular/forms';
import { getMMDDYYYYhhmm } from '../../../shared/utils';
import * as _ from 'lodash';
import {
  screenConfigurationType,
  apiConfigurationType,
  uiDisplay,
} from './constants';
import { SharedServices } from '../../../shared/services/shared.services';

/*
 * Last Updated:
 * Created initial Configuration Service for Add / Edit / Delete - Sai Tagore - 10th April 2020.
 */

@Injectable()
export class ConfigurationService {
  loggedinUser!: User;
  equipmentList: any[] = [];
  checklist: any[] = [];
  clearTabHeaderGlobalSearch: EventEmitter<boolean> = new EventEmitter();

  /*
   * Server has different set of mappings. As we dynamically prepare the page `_` may not look good in URL
   * Once you have all the processing convert type of configuration to server understandable one.
   */
  configurationDisplay: any = [
    {
      configurationType: screenConfigurationType.BASE,
      apiConfigurationType: apiConfigurationType.BASE,
      uiDisplay: uiDisplay.base,
    },
    {
      configurationType: screenConfigurationType.FLEET,
      apiConfigurationType: apiConfigurationType.EQUIPMENT,
      uiDisplay: uiDisplay.fleet,
    },
    {
      configurationType: screenConfigurationType.SEATPOSITION,
      apiConfigurationType: apiConfigurationType.SEAT,
      uiDisplay: uiDisplay.seatPosition,
    },
    {
      configurationType: screenConfigurationType.NAVIGATION,
      apiConfigurationType: apiConfigurationType.NAVLINK_DATA,
      uiDisplay: uiDisplay.navigation,
    },
    {
      configurationType: screenConfigurationType.SCREENBUCONFIG,
      apiConfigurationType: apiConfigurationType.SCREEN_BU,
      uiDisplay: uiDisplay.screenBUConfiguration,
    },
    {
      configurationType: screenConfigurationType.BUSINESSUNIT,
      apiConfigurationType: apiConfigurationType.BUSINESS_UNIT,
      uiDisplay: uiDisplay.businessUnit,
    },
    {
      configurationType: screenConfigurationType.BUSINESSUNITDATA,
      apiConfigurationType: apiConfigurationType.BUSINESS_UNIT_DATA,
      uiDisplay: uiDisplay.businessUnitData,
    },
    {
      configurationType: screenConfigurationType.DATAFIELDCONFIG,
      apiConfigurationType: apiConfigurationType.DATA_FIELD_CONFIG,
      uiDisplay: uiDisplay.dataFieldConfiguration,
    },
    {
      configurationType: screenConfigurationType.BASECOORDINATES,
      apiConfigurationType: apiConfigurationType.BASE_COORDINATES,
      uiDisplay: uiDisplay.baseCoordinates,
    },
    {
      configurationType: screenConfigurationType.CODETYPE,
      apiConfigurationType: apiConfigurationType.CODE_TYPE,
      uiDisplay: uiDisplay.codeTypeData,
    },
    {
      configurationType: screenConfigurationType.STATIONCHECKLISTMAPPING,
      apiConfigurationType: apiConfigurationType.STATION_CHECKLIST_MAPPING,
      uiDisplay: uiDisplay.stationCheckListMapping,
    },
    {
      configurationType: screenConfigurationType.CHECKLIST,
      apiConfigurationType: apiConfigurationType.CHECKLIST,
      uiDisplay: uiDisplay.checkList,
    },
    {
      configurationType: screenConfigurationType.CHECKLISTITEM,
      apiConfigurationType: apiConfigurationType.CHECKLIST_ITEM,
      uiDisplay: uiDisplay.checklistItem,
    },
    {
      configurationType: screenConfigurationType.COMMANDPARAMETER,
      apiConfigurationType: apiConfigurationType.SYSTEM_DATA,
      uiDisplay: uiDisplay.commandparameter,
    },
    {
      configurationType: screenConfigurationType.STATIONCHECKLISTITEM,
      apiConfigurationType: apiConfigurationType.STATION_CHECKLIST_ITEM,
      uiDisplay: uiDisplay.stationChecklistItem,
    },
    {
      configurationType: screenConfigurationType.PRINTERLOCATION,
      apiConfigurationType: apiConfigurationType.PRINTER_LOCATION,
      uiDisplay: uiDisplay.printerLocation,
    },
    {
      configurationType: screenConfigurationType.USERS,
      apiConfigurationType: apiConfigurationType.USERS,
      uiDisplay: uiDisplay.users,
    },
  ];

  constructor(
    private _httpService: HttpService,
    private _sharedServices: SharedServices,
    private _store: Store<fromRoot.State>,
  ) {
    this._store.pipe(select(fromRoot.getAppUserState)).subscribe((data) => {
      this.loggedinUser = data;
    });
  }

  buildConfigurationRequest(
    configurationType: string,
    configFilters: any,
    actionType?: string,
    payload?: string,
  ): ConfigurationRequest {
    const configurationRequest: ConfigurationRequest = {
      airlineCode: this.loggedinUser.airlineCode,
      businessUnit: this.loggedinUser.businessUnit
        ? this.loggedinUser.businessUnit
        : '',
      employeeLogin: this.loggedinUser.employeeNumber.toString(),
      appSessionId: this._sharedServices.getAppSessionID(),
      siteMinderEmployeeId: this.loggedinUser.employeeNumber.toString(),
      gets: this.retrieveConfigurationDetails(
        configurationType,
        'apiConfigurationType',
      ),
      configFilters: configFilters,
      // Dynamically add a request without having to create 2 methods, rebuild an object.
      // Adds these 2 JSON elements only if actionType is not null - Sai Tagore.
      ...(actionType && {
        actionType: actionType,
        configData: payload,
      }),
    };
    return configurationRequest;
  }

  /*
   * Server returns the datatype of the field that needs to be displayed on UI. Convert server side returned datatype to UI understandable field
   * {"headerName":"seatDescription","displayName":"Description","headerDataType":"java.lang.String"}
   */
  findFormControlType(javaDataType: string): string {
    if (caseSensitiveStringContains(javaDataType, 'string')) {
      return 'text';
    } else if (caseSensitiveStringContains(javaDataType, 'boolean')) {
      return 'checkbox';
    } else if (caseSensitiveStringContains(javaDataType, 'sql.Timestamp')) {
      return 'datepicker';
    } else if (caseSensitiveStringContains(javaDataType, 'integer')) {
      return 'number';
    }
    return 'text';
  }

  /*
     * One common service for all the configuration.
     * Request includes gets paramater that will return appropriate configuration item.
     * Sample Request
     * {
     "airlineCode":"AA",
     "businessUnit":"AAPI",
     "employeeLogin":"361",
     "appSessionId":"Platform:iOS/Model:Apple iPhone/OS:10.2",
     "siteMinderEmployeeId":"215266",
     "gets": [
     "BASE"   <--- configurationType
     ]
     }
     */
  getConfigurationData(
    configurationType: string,
    configFilters: any,
  ): Observable<any> {
    if (
      configurationType ===
      screenConfigurationType.STATIONCHECKLISTMAPPING.toString()
    ) {
      this.getDependencyConfigurationForAPI();
    }
    const url = `${environment.apiBaseURL}/admin/getAdminConfigData`;

    return this._httpService.postService(
      url,
      this.buildConfigurationRequest(configurationType, configFilters),
    );
  }

  updateConfigurationData(
    configurationAction: string,
    configurationType: string,
    requestPayload: any,
  ): Observable<any> {
    const url = `${environment.apiBaseURL}/admin/manageAdminConfigData`;

    return this._httpService.postService(
      url,
      this.buildConfigurationRequest(
        configurationType,
        null,
        configurationAction,
        requestPayload,
      ),
    );
  }

  getDependencyConfigurationForAPI(): void {
    this.getConfigurationData(
      screenConfigurationType.FLEET.toString(),
      null,
    ).subscribe((response) => {
      if (response && response.configData) {
        this.equipmentList = [];
        this.equipmentList.push({
          optionName: '',
          value: '',
        });
        response.configData.forEach((equipment: any) => {
          this.equipmentList.push({
            optionName: equipment.equipmentTypeCode,
            value: equipment.equipmentTypeCode,
          });
        });
      }
    });
    this.getConfigurationData(
      screenConfigurationType.CHECKLIST.toString(),
      null,
    ).subscribe((response) => {
      if (response && response.configData) {
        this.checklist = [];
        response.configData.forEach((checklistItem: any) => {
          this.checklist.push({
            optionName: checklistItem.chklistDesc,
            value: checklistItem.chklistID,
          });
        });
      }
    });
  }

  buildFormData(
    rowData: any,
    columnHeaders: any,
    operationType: string,
    configurationType: string,
  ): FormFieldConfig[] {
    const finalFormData: FormFieldConfig[] = [];

    columnHeaders.forEach((headerName: any) => {
      let optionsList: { optionName: string; value: string }[] | undefined;
      let dataFieldType = null;
      if (
        configurationType ===
        screenConfigurationType.STATIONCHECKLISTMAPPING.toString()
      ) {
        if (headerName.columnDef === 'equipCode') {
          dataFieldType = 'select';
          optionsList = this.equipmentList;
        } else if (headerName.columnDef === 'chklistID') {
          optionsList = this.checklist;
          dataFieldType = 'select';
        } else if (headerName.columnDef === 'chklistDesc') {
          return;
        }
      }

      if (
        (configurationType ===
          screenConfigurationType.COMMANDPARAMETER.toString() &&
          headerName.header === 'Data') ||
        headerName.header === uiDisplay.commandparameter
      ) {
        finalFormData.push(this.addFormFieldType(headerName));
      }

      // Do not add ID fields for insert operation. They will be auto generated by server when they get saved in DB.
      if (
        !('INSERT' === operationType && headerName.addDisabled) &&
        !caseSensitiveStringContains(
          headerName.header,
          appStrings.lastModifiedText,
        )
      ) {
        if (
          configurationType ===
            screenConfigurationType.COMMANDPARAMETER.toString() &&
          headerName.header === 'Data'
        )
          headerName.header = uiDisplay.commandparameter;

        const fieldType = dataFieldType
          ? dataFieldType
          : this.findFormControlType(headerName.fieldType);
        const formFieldConfig: FormFieldConfig = {
          name: headerName.columnDef,
          type: fieldType,
          placeholder: headerName.header,
          // eslint-disable-next-line no-nested-ternary
          value: rowData
            ? fieldType !== 'datepicker'
              ? rowData[headerName.columnDef]
              : getMMDDYYYYhhmm(rowData[headerName.columnDef], true)
            : this.setDefaultValues(fieldType),
          label: headerName.header,
          validation: this.buildValidators(headerName),
          disabled: 'INSERT' === operationType ? false : headerName.disabled,
          minlength: headerName.minlength,
          maxlength: headerName.maxlength,
          required: headerName.required,
          options: optionsList,
        };
        finalFormData.push(formFieldConfig);
      }
    });

    return finalFormData;
  }

  addFormFieldType(headerName: any): FormFieldConfig {
    const formFieldConfig: FormFieldConfig = {
      name: 'codeType',
      type: 'text',
      placeholder: apiConfigurationType.COMMAND_PARAMETER,
      value: apiConfigurationType.COMMAND_PARAMETER,
      label: 'Type',
      validation: this.buildValidators(headerName),
      disabled: true,
      minlength: headerName.minlength,
      maxlength: headerName.maxlength,
      required: headerName.required,
    };
    return formFieldConfig;
  }

  buildValidators(columnData: any): Array<any> {
    const validatorsArray: any = [];

    if (columnData.required) {
      validatorsArray.push(Validators.required);
    }

    validatorsArray.push(Validators.minLength(columnData.minlength));
    validatorsArray.push(Validators.maxLength(columnData.maxlength));

    return validatorsArray;
  }

  /*
   * Function that will take configuration type as input and return corresponding value from JSON object.
   * For Example if the input is base and if it is for API call then return value will be BASE
   * this.retrieveConfigurationDetails( configurationType, 'apiConfigurationType'),
   * if request is for displaying in UI then will return uiDisplay value.
   */
  retrieveConfigurationDetails(
    configurationType: string,
    typeOfOperation: string,
  ): string {
    return _.result(
      _.find(this.configurationDisplay, function (obj: any): any {
        return obj.configurationType === configurationType;
      }),
      typeOfOperation,
    );
  }

  retrieveConfigurationUiDisplay(configurationType: string): string {
    return _.find(this.configurationDisplay, function (e: any): any {
      if (e.configurationType === configurationType) {
        return e.uiDisplay;
      }
    }).uiDisplay;
  }

  setDefaultValues(fieldType: string): any {
    if (fieldType === 'checkbox') {
      return false;
    } else if (fieldType === 'number') {
      return 1;
    }
    return '';
  }

  returnDatatypeBasedOnField(property: string, tableColumnList: any): string {
    const value = _.filter(tableColumnList, { columnDef: property });
    return value[0].fieldType;
  }
}
