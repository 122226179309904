import { Injectable } from '@angular/core';
import { AdminTypes } from '../../constants';
import { reportsURL } from '../../../environments/environment';
export interface Menu {
  state: string;
  name: string;
  type: string;
  icon?: string;
  link?: string;
  access?: Array<any>;
  submenu?: Array<Menu>;
}

const MENUITEMS = [
  {
    state: 'admin/searchCCIMessages',
    name: 'Messages',
    type: 'link',
    icon: 'mail_outline',
    access: [AdminTypes.ALL],
  },
  {
    state: 'admin/users',
    name: 'Users',
    type: 'link',
    icon: 'people_outline',
    access: [AdminTypes.MASTER, AdminTypes.BUSINESS_UNIT],
  },
  {
    state: 'admin/reports',
    name: 'Reports',
    type: 'sub',
    icon: 'insert_chart_outlined',
    access: [AdminTypes.ALL],
    submenu: [
      {
        state: 'useractivityreport',
        name: 'User Activity Report',
        type: 'link',
        access: [AdminTypes.ALL],
      },
      {
        state: 'tripsigninreport',
        name: 'Trip Sign-In Report',
        type: 'link',
        access: [AdminTypes.ALL],
      },
      {
        state: 'mobiletripsigninreport',
        name: 'Mobile TripSignIn Report',
        type: 'link',
        access: [AdminTypes.ALL],
      },
      {
        state: 'probationarypilotsreport',
        name: 'Probationary Pilots Report',
        type: 'link',
        access: [AdminTypes.MASTER, AdminTypes.BUSINESS_UNIT],
      },
      {
        state: 'employeemessagereadreport',
        name: 'Employee Message Read Report',
        type: 'link',
        access: [AdminTypes.ALL],
      },
      {
        state: 'probationarypilotsummary',
        name: 'Proby Reports',
        type: 'link',
        access: [AdminTypes.MASTER, AdminTypes.BUSINESS_UNIT, AdminTypes.BASE],
      },
      // {
      //   state: 'ProbyReport',
      //   name: 'Proby Reports',
      //   type: 'external-link',
      //   link: `${
      //     reportsURL && reportsURL.probyReport ? reportsURL.probyReport : ``
      //   }`,
      //   access: [AdminTypes.MASTER, AdminTypes.BUSINESS_UNIT, AdminTypes.BASE],
      // },
      {
        state: 'useractivityreportpbi',
        name: 'User Activity Report',
        type: 'external-link',
        link: `${
          reportsURL && reportsURL.userActivityReport
            ? reportsURL.userActivityReport
            : ``
        }`,
        access: [AdminTypes.ALL],
      },
      {
        state: 'tripsigninreportpbi',
        name: 'Trip Sign-In Report',
        type: 'external-link',
        link: `${
          reportsURL && reportsURL.tripSignInActivityReport
            ? reportsURL.tripSignInActivityReport
            : ``
        }`,
        access: [AdminTypes.ALL],
      },
      {
        state: 'mobiletripsigninreportpbi',
        name: 'Mobile TripSignIn Report',
        type: 'external-link',
        link: `${
          reportsURL && reportsURL.mobileTripSignInActivityReport
            ? reportsURL.mobileTripSignInActivityReport
            : ``
        }`,
        access: [AdminTypes.ALL],
      },
    ],
  },
  {
    state: 'admin/tools',
    name: 'Tools',
    type: 'sub',
    icon: 'build',
    access: [AdminTypes.MASTER],
    submenu: [
      {
        state: 'sqltool',
        name: 'Sql Tool',
        type: 'link',
        access: [AdminTypes.MASTER],
      },
    ],
  },
  {
    state: 'admin/configuration',
    name: 'Config',
    type: 'sub',
    icon: 'settings',
    access: [AdminTypes.MASTER, AdminTypes.BUSINESS_UNIT],
    submenu: [
      {
        state: 'base',
        name: 'Base',
        type: 'link',
        access: [AdminTypes.MASTER, AdminTypes.BUSINESS_UNIT],
      },
      {
        state: 'basecoordinates',
        name: 'Base Coordinates',
        type: 'link',
        access: [AdminTypes.MASTER, AdminTypes.BUSINESS_UNIT],
      },
      {
        state: 'businessunit',
        name: 'Business Unit',
        type: 'link',
        access: [AdminTypes.MASTER],
      },
      {
        state: 'businessunitdata',
        name: 'Business Unit Data',
        type: 'link',
        access: [AdminTypes.MASTER],
      },
      {
        state: 'codetype',
        name: 'Code Type Data',
        type: 'link',
        access: [AdminTypes.MASTER],
      },
      {
        state: 'checklist',
        name: 'Checklist',
        type: 'link',
        access: [AdminTypes.MASTER, AdminTypes.BUSINESS_UNIT],
      },
      {
        state: 'checklistitem',
        name: 'Checklist Item',
        type: 'link',
        access: [AdminTypes.MASTER],
      },
      {
        state: 'commandparameter',
        name: 'Command Parameter',
        type: 'link',
        access: [AdminTypes.MASTER],
      },
      {
        state: 'datafieldconfig',
        name: 'Data Field',
        type: 'link',
        access: [AdminTypes.MASTER],
      },
      {
        state: 'fleet',
        name: 'Fleet',
        type: 'link',
        access: [AdminTypes.MASTER, AdminTypes.BUSINESS_UNIT],
      },
      {
        state: 'navigation',
        name: 'Navigation',
        type: 'link',
        access: [AdminTypes.MASTER, AdminTypes.BUSINESS_UNIT],
      },
      {
        state: 'printerlocation',
        name: 'Printer Location',
        type: 'link',
        access: [AdminTypes.MASTER, AdminTypes.BUSINESS_UNIT],
      },
      {
        state: 'screenbuconfig',
        name: 'Screen BU Configuration',
        type: 'link',
        access: [AdminTypes.MASTER],
      },
      {
        state: 'seatposition',
        name: 'Seat / Position',
        type: 'link',
        access: [AdminTypes.MASTER, AdminTypes.BUSINESS_UNIT],
      },
      {
        state: 'stationchecklistmapping',
        name: 'Station Checklist Mapping',
        type: 'link',
        access: [AdminTypes.MASTER, AdminTypes.BUSINESS_UNIT],
      },
      {
        state: 'stationchecklistitem',
        name: 'Station Checklist Item',
        type: 'link',
        access: [AdminTypes.MASTER, AdminTypes.BUSINESS_UNIT],
      },
    ],
  },
];

@Injectable()
export class MenuItems {
  getMenuitem(): Menu[] {
    return MENUITEMS;
  }
}
