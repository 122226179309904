import * as moment from 'moment';

export function getMMDDYYYYhhmm(inputValue: string, is24Hour: boolean): string {
  if (is24Hour === true) {
    return moment(inputValue).format('MM/DD/YYYY HH:mm').toUpperCase(); ///  Padded:  If you were to use padded format like 06:30 or 08:30 then go for 'HH' for 24 hours
    // https://docs.angularjs.org/api/ng/filter/date
  }
  return moment(inputValue).format('MM/DD/YYYY H:mm').toUpperCase(); //// Non-Padded:  If you were to use non padded format like 6:30 or 8:30 then go for 'H' for 12 hours
  // https://docs.angularjs.org/api/ng/filter/date
}

export function currentDateYYYYMMddFormat(
  offset: number,
  unit: moment.DurationInputArg2 = 'months',
): any {
  return moment().subtract(offset, unit).format('YYYY-MM-DD');
}

export function convertToDateFormat(date: Date, format: string): any {
  return moment(date).format(format);
}

export function formatDate(date: string | Date, format = 'MM/DD/YYYY'): any {
  return moment(date).format(format);
}

export function currentDateInMMDDYYYYHHMMFormat(): any {
  return moment().format('MM/DD/YYYY HH:mm').toUpperCase();
}

export const dateFormatters = {
  monthDayYearHourMins: 'MM/DD/YYYY HH:mm',
};

export const reportDateFormat = {
  startDate: 'MM/DD/YYYY 00:00',
  endDate: 'MM/DD/YYYY 23:59',
};

export const determineDateRange = (
  optionSelected: string,
  dateRangeConstants: any,
): any => {
  let startDate = 3;
  const endDate = 0;
  let unit: moment.DurationInputArg2 = 'months';
  switch (optionSelected) {
    // case dateRangeConstants.oneDay: {
    //   startDate = 1;
    //   unit = 'days';
    //   break;
    // }
    case dateRangeConstants.threeDays: {
      startDate = 3;
      unit = 'days';
      break;
    }
    case dateRangeConstants.oneWeek: {
      startDate = 7;
      unit = 'days';
      break;
    }
    case dateRangeConstants.oneMonth: {
      startDate = 1;
      break;
    }
    // case dateRangeConstants.threeMonths: {
    //   startDate = 3;
    //   break;
    // }
    // * Kevin: Anything past 3 months is broken due to backend / db issue.
    // case dateRangeConstants.sixMonths: {
    //   startDate = 6;
    //   break;
    // }
    // case dateRangeConstants.customDateRange: {
    //   return {
    //     effectiveStartDate: convertToDateFormat(
    //       this.messageSearchFormGroup.get('startDate')?.value,
    //       'YYYY-MM-DD',
    //     ),
    //     effectiveEndDate: convertToDateFormat(
    //       this.messageSearchFormGroup.get('endDate')?.value,
    //       'YYYY-MM-DD',
    //     ),
    //   };
    // }
    case 'noDate': {
      return {};
    }
  }
  const dateRangeSelected: any = {
    effectiveStartDate: currentDateYYYYMMddFormat(startDate, unit),
    effectiveEndDate: currentDateYYYYMMddFormat(endDate, unit),
  };
  return dateRangeSelected;
};

export const getCustomDateRange = (startDate: Date, endDate: Date): any => {
  return {
    effectiveStartDate: convertToDateFormat(startDate, 'YYYY-MM-DD'),
    effectiveEndDate: convertToDateFormat(endDate, 'YYYY-MM-DD'),
  };
};

export const getToday = () => {
  return moment().toDate();
};

export const addOneMonthsFromSelectedDate = (
  startDate: Date,
  numberCount: number,
  format = 'YYYY-MM-DD',
) => {
  return moment(startDate).add(numberCount, 'M').format(format);
};

export const convertToDate = (date: string) => {
  return moment(date).toDate();
};
