export const appStrings = {
  lastModifiedText: 'last modified',
  showFilters: 'Show Filters',
  hideFilters: 'Hide Filters',
};

export const crewTypeValidation = {
  gate: 'GATE',
};

export const alphaNumericPatternMatch = '^[A-Za-z0-9]*$';

export const businessUnits = {
  aapi: 'AAPI',
  aafa: 'AAFA',
  mqpi: 'MQPI',
  mqfa: 'MQFA',
};

export enum AdminTypes {
  READONLY = 'Read Only',
  BASE = 'BASE',
  FLEET = 'FLEET',
  BUSINESS_UNIT = 'BUS_UNIT',
  SYSTEMS = 'FLIGHT',
  MASTER = 'MASTER',
  ALL = 'ALL',
}

export const businessUnitsMap = new Map([
  ['AAPI', 'AA Pilots'],
  ['AAFA', 'AA Flight Attendants'],
  ['MQPI', 'Envoy Pilots'],
  ['MQFA', 'Envoy Flight Attendants'],
  ['AGNT', 'Station Agent'],
  ['NAPI', 'Not Applicable'],
]);

export const operationType = {
  INSERT: 'INSERT',
  VIEW: 'VIEW',
  DELETE: 'DELETE',
  UPDATE: 'UPDATE',
};

export const validatorPatterns = {
  allowNumberOnly: '^[0-9]*$',
  email: '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$',
};

export enum ExportReportType {
  PDF = 'PDF',
  XLSX = 'XLSX',
}
