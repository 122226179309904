/* eslint-disable @typescript-eslint/naming-convention */

import { HttpHeaders } from '@angular/common/http';

// https://ccicoredev-eastus-apim.azure-api.net/admin
// https://services.cci-dev.aa.com/admin
export const apiDomain = 'https://services.cci-dev.aa.com';

export const webDomain = 'https://admin.cci-dev.aa.com';

export const environment = {
  production: false,
  apiBaseURL: `${apiDomain}`,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  RequestOptions: {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  },
  crewWebAppURL: `${webDomain}/index.html`,
  snackbarDuration: 10000,
  apiTimeOut: 75000,
  pingAuthAuthority: 'https://idptest.aa.com',
  pingAuthClientId: 'CrewChkIn-141609-test-cvDGl', // CrewChkIn-141609-test-BLLyz
};

export const reportsURL = {
  probyReport: `${webDomain}/CrewCheckin/ProbyReport.jsp`,
  userActivityReport:
    'https://app.powerbi.com/groups/f543c238-6ef6-42ea-a7a5-5432d616b912/rdlreports/80d2a9fb-2e40-4ce8-b4ae-2fa240c03a9d?ctid=49793faf-eb3f-4d99-a0cf-aef7cce79dc1&pbi_source=linkShare',
  tripSignInActivityReport:
    'https://app.powerbi.com/groups/f543c238-6ef6-42ea-a7a5-5432d616b912/rdlreports/6e2987aa-6b3d-4acc-92dc-42f040901c06?ctid=49793faf-eb3f-4d99-a0cf-aef7cce79dc1&pbi_source=linkShare',
  mobileTripSignInActivityReport:
    'https://app.powerbi.com/groups/f543c238-6ef6-42ea-a7a5-5432d616b912/rdlreports/493bccc6-cdcf-4ea2-8543-f7a958d73150?ctid=49793faf-eb3f-4d99-a0cf-aef7cce79dc1&pbi_source=linkShare',
};
