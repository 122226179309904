import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PingAuthGuard } from '@techops-ui/ping-authentication';
import { CCIAppLayoutComponent } from '../layout/cciapp-layout/cciapp-layout.component';
import { AuthComponent } from './auth/auth.component';
import { AdminGuard } from '../guards/admin.guard';

const routes: Routes = [
  {
    path: '',
    component: CCIAppLayoutComponent,
    canActivate: [PingAuthGuard],
    canActivateChild: [AdminGuard],
    children: [
      {
        path: 'admin',
        loadChildren: () =>
          import('./admin/admin.module').then((m) => m.AdminModule),
        data: { title: 'admin', titleI18n: 'admin' },
      },
    ],
  },
  {
    path: 'sessions',
    loadChildren: () =>
      import('./sessions/sessions.module').then((m) => m.SessionsModule),
  },
  { path: '**', redirectTo: 'auth' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class RoutesRoutingModule {}
