export function isEmpty(str: string): boolean {
  const isEmptyStr = !str || /^\s*$/.test(str);
  return isEmptyStr;
}

export function caseSensitiveStringContains(
  str: string,
  searchString: string,
): boolean {
  if (!isEmpty(str)) {
    return str.toLowerCase().indexOf(searchString.toLowerCase()) !== -1;
  }
  return false;
}

export function toUpperCase(str: string): string {
  return str.toUpperCase();
}

export function removeCData(text: string): string {
  return text.replace('<![CDATA[', '').replace(']]>', '');
}

export function formatNewLines(text: string): string {
  return text.replace(/(?:\r\n|\r|\n)/g, '<br />');
}

export function verifyFileExtension(text: string): string {
  const fileExtensionPattern = /\.([0-9a-z]+)(?=[?#])|(\.)(?:[\w]+)$/gim;
  const match = text.match(fileExtensionPattern);
  if (match) return match[0];
  return text;
}

export function isNumeric(value: string): boolean {
  return /^\d+$/.test(value);
}

export function strToNum(val: string) {
  return !isNaN(Number(val)) ? Number(val) : 0;
}
